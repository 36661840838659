body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin: 0;
  padding-top: 60px;
  font-family: 'Courier New', Courier, monospace;
  background-color: rgba(0, 0, 0, 0.95);
}

.page-container {
  display: flex;
  max-width: 100%;
  margin: 0 10px; /* Add margin to the container */
}

.text-container {
  width: 300px;
  padding: 20px;
  background-color: #f0f0f00a;
  color: #ffffff;
  margin-right: 10px; /* Add margin to the right */
}

.script-container {
  width: 700px;
  height: 700px;
  overflow: hidden;
  position: relative;
}


.text-container-right {
  width: 300px;
  padding: 20px;
  background-color: #f0f0f00a;
  color: #ffffff;
}

.svg-image {
  max-width: 300px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.button-container {
  margin-top: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
}

.button {
  width: auto; /* Set the width to your desired fixed value */
  padding: 10px 20px;
  background-color: #02020200;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border: 1px solid #ffffff; /* Set border color as per your preference */
  border-radius: 0; /* Set border-radius to 0 for sharp edges */
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px; /* Add some spacing between buttons */
}

.button:hover {
  background-color: white; /* Change background color on hover */
  color: black; /* Change text color on hover */
  border-color: #fff; /* Change border color on hover */
}

.text-on-mobile {
  display: none; /* Hide the text by default */
}

@media screen and (max-width: 600px) {
  .page-container {
    flex-direction: column;
  }
  .text-container {
    width: 300px;
    margin: 0;
    margin-bottom: 10px;
  }
  .script-container {
    display: none;
  }


  .text-container-right {
    width: 300px;
    margin: 0;
    margin-bottom: 10px;
  }
}
