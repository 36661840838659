

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    margin: 0;
    padding-top: 60px;
    font-family: 'Courier New', Courier, monospace;
    background-color: rgba(0, 0, 0, 0.95);
}

.container {
    display: flex;
    max-width: 100%;
    margin: 0 10px; /* Add margin to the container */
}

.info-container {
    width: 300px;
    padding: 20px;
    background-color: #f0f0f00a;
    color: #ffffff;
    margin-right: 10px; /* Add margin to the right */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    max-width: 920px;
    margin: 0 auto;
}

.button-container {
    margin-top: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}

.button {
    width: auto; /* Set the width to your desired fixed value */
    padding: 0px 0px;
    background-color: #02020200;
    color: #fff;
    text-decoration: none;
    text-align: center;
    border: 1px solid #ffffff; /* Set border color as per your preference */
    border-radius: 0; /* Set border-radius to 0 for sharp edges */
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 0px; /* Add some spacing between buttons */
}

.button:hover {
    background-color: white; /* Change background color on hover */
    color: black; /* Change text color on hover */
    border-color: #fff; /* Change border color on hover */
  }

.buttonDesc {
    margin-bottom: 0;
}

.grid-item {
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.grid-item img {
    width: 100%; /* Make sure the image fills the container width */
    height: 100%; /* Make sure the image fills the container height */
    border: none;
    object-fit: cover; /* Crop the sides of the image to fit */
}

.wallet-item {
    width: 300px;
    height: 300px;
    padding: 20px;
    overflow: hidden;
    object-fit: cover; /* Crop the sides of the image to fit */
}

.footnote {
    font-size: 12px;
    color: grey;
  }

  .logo {
    width: 20px;
    height: 20px;
    padding-right: 10px;
  }

@media screen and (max-width: 600px) {
    .grid-item {
        height: 300px;
    }

    .grid-container {
        grid-template-columns: 1fr;
    }

    .container {
        flex-direction: column;
    }

    .info-container {
        width: 260px;
        margin: 0 0 10px 0; /* Remove right margin on mobile */
        text-align: center; /* Center text content within .info-container */
    }
}
